<template>
    <div class='index'>
      <router-view />
    </div>
  </template>
  
  <script>
  export default {
    name: 'chgLocalIndex',
    data () {
      return {
  
      }
    },
    mounted () {
  
    },
    methods: {
  
    }
  }
  </script>
  <style lang='scss' scoped>
  
  </style>
  